<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-data-table
        :headers="headers"
        :items="blogs"
        :items-per-page="10"
        :search="searchBlog"
        :loading="loadingData"
        :expanded="expanded"
        single-expand
        show-expand
        item-key="id"
        sort-by="id"
        disable-sort
        no-data-text="No hay datos para mostrar..."
        loading-text="Cargando los datos, por favor espere!"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          disableItemsPerPage: false,
          itemsPerPageAllText: 'Todas',
          itemsPerPageText: 'Filas por página:',
        }"
      >
        <template v-slot:top>
          <v-toolbar flat class="align-center">
            <v-toolbar-title
              :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1' : ''"
            >
              Blogs
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-responsive
              max-width="300"
              height="45"
              class="hidden-sm-and-down"
            >
              <v-text-field
                id="findtext-blog"
                label="Buscar.."
                name="findblog"
                outlined
                dense
                single-line
                clearable
                background-color="white"
                hide-details
                v-model="searchBlog"
                class="white--text mt-0 pt-0 mr-2"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-responsive>
            <v-dialog
              :fullscreen="$vuetify.breakpoint.smAndDown"
              v-model="dialog"
              max-width="1000px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="user.roles == 'ADMIN' || user.roles == 'COPARMEX'"
                  :small="$vuetify.breakpoint.smAndDown"
                  color="blue darken-1"
                  dark
                  depressed
                  elevation="0"
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="newItem"
                >
                  Adicionar
                </v-btn>
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title class="text-h6 grey lighten-2">
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      <v-row class="mt-0">
                        <v-col cols="12">
                          <v-text-field
                            dense
                            v-model="editedItem.title"
                            label="Título del Post - Español"
                            :rules="textRules"
                            :disabled="saveChanges"
                            required
                          >
                          </v-text-field>
                          <v-text-field
                            dense
                            :disabled="saveChanges"
                            v-model="editedItem.title_english"
                            label="Título del Post - Inglés"
                            :rules="textRules"
                            required
                          >
                          </v-text-field>
                          <v-textarea
                            v-model="editedItem.content"
                            label="Contenido del Post - Español"
                            :rules="textRules"
                            no-resize
                            rows="6"
                            :disabled="saveChanges"
                            counter
                            required
                          >
                          </v-textarea>

                          <v-textarea
                            v-model="editedItem.content_english"
                            label="Contenido del Post - Inglés"
                            :rules="textRules"
                            no-resize
                            :disabled="saveChanges"
                            rows="6"
                            counter
                            required
                          >
                          </v-textarea>
                          <v-select
                            :disabled="saveChanges"
                            dense
                            v-model="editedItem.categories"
                            :items="categoriesList"
                            :rules="[
                              (v) =>
                                !!v || 'Al menos una Categoría es obligatoria',
                            ]"
                            item-text="name"
                            item-value="id"
                            return-object
                            label="Categorías del Post"
                            multiple
                            item-color="primary"
                            small-chips
                            hint="Selecciones las Categorías asociadas a este Post"
                            persistent-hint
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-img
                            :src="
                              imagePreview != null
                                ? imagePreview
                                : require('@/assets/images/no-image.jpg')
                            "
                            :lazy-src="require('@/assets/images/no-image.jpg')"
                            max-height="300"
                            max-width="100%"
                            aspect-ratio="1"
                            class="white--text align-center justify-center elevation-0"
                          >
                          </v-img>
                        </v-col>
                        <v-col cols="12">
                          <v-file-input
                            v-model="imageFile"
                            accept="image/png, image/jpeg, image/bmp"
                            show-size
                            clearable
                            :disabled="saveChanges"
                            label="Cargar/Cambiar imágen"
                            placeholder="Archivo de imágen"
                            prepend-icon="mdi-camera"
                            @change="changePreviewImage()"
                          >
                          </v-file-input>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            dense
                            :disabled="saveChanges"
                            v-model="editedItem.caption_image"
                            label="Título de la imagen (opcional)"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :disabled="saveChanges" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid"
                      color="primary"
                      @click="save"
                      :loading="saveChanges"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline">
                  ¿Est&aacute; seguro que desea eliminar este elemento?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" :disabled="saveChanges" text @click="closeDelete">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" :loading="saveChanges" @click="deleteItemConfirm">
                    Proceder
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogError" persistent max-width="500">
              <v-card>
                <v-card-title class="text-h6 primary lighten-2">
                  <span class="headline">Error de Validación</span>
                </v-card-title>

                <v-card-text>
                  <div class="text-h6 pa-12">{{ messageError }}!</div>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn text @click.stop="closeDialogError"> Cerrar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip top v-if="user.roles == 'ADMIN' || user.roles == 'COPARMEX'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <span class="text-h5">No hay registros en el listado!</span>
          <br />
          <v-btn color="primary" @click="getBlogs"> Recargar </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <v-container fluid class="fill-height">
              <v-row justify="start" class="ma-0">
                <h3>{{ item.name }}</h3>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="12" md="4" class="pt-3">
                  <v-card outlined flat class="fill-height pt-0" width="100%">
                    <!--max-height="250"
                                            :height="$vuetify.breakpoint.xs ? 250: 200"
                                            :max-width="$vuetify.breakpoint.width .xs ? 280 : '100%'"
                                        -->
                    <v-img
                      :src="
                        item.pictures[0] != null
                          ? item.pictures[0].url
                          : require('@/assets/images/no-image.jpg')
                      "
                      :lazy-src="require('@/assets/images/no-image.jpg')"
                      :max-width="$vuetify.breakpoint.xs ? 290 : '100%'"
                      height="250"
                      class="white--text align-center justify-center elevation-0"
                    >
                    </v-img>
                  </v-card>
                </v-col>
                <v-col cols="12" md="8" class="pt-0">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span
                        >T&iacute;tulo - Espa&ntilde;ol:

                        {{ item.title }}
                      </span>
                    </v-card-title>
                    <v-card-title>
                      <span
                        >T&iacute;tulo - Ingl&eacute;s:

                        {{ item.title_english }}
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <span class="text-subtitle-1 font-weight-bold"
                        >Contenido - Espa&ntilde;ol:
                      </span>
                      {{ item.content }}
                    </v-card-text>

                    <v-card-text>
                      <span class="text-subtitle-1 font-weight-bold"
                        >Contenido - Inglés:
                      </span>
                      {{ item.content_english }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                  <div v-if="item.categories">
                    <span class="text-subtitle-1">Categorias:</span><br />
                    <v-chip
                      :small="$vuetify.breakpoint.smAndDown"
                      v-for="(s, i) in item.categories"
                      :key="i"
                      class="mr-1"
                    >
                      {{ s.name }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import blogsService from "@/providers/BlogsService";
import blogsCatService from "@/providers/BlogsCategoriesService";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "BlogsModuleComponent",
  data: () => ({
    focus: false,
    snackBar: false,
    snackText: "",
    valid: true,
    searchBlog: "",
    loadingData: false,
    dialog: false,
    dialogDelete: false,
    dialogError: false,
    messageError: "",
    imageFile: null,
    imagePreview: null,
    selectedImageIndex: -1,
    categoriesList: [],
    blogs: [],
    expanded: [],
    headers: [
      { text: "", value: "data-table-expand", width: 5 },
      { text: "Id", value: "id", align: " d-none" }, // para ocultar la columna Id.
      {
        text: "Título del Post",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "Acciones", value: "actions", width: 60, sortable: false },
    ],
    textRules: [(v) => !!v || "Los datos de este campo son obligatorio!"],
    imageRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "El tamaño de la imagen no debe de sobrepasar los 2 MB!",
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      title: "",
      title_english: "",
      content: "",
      content_english: "",
      caption_image: "",
      author: "",
      categories: [],
      pictures: [],
    },
    defaultItem: {
      id: 0,
      title: "",
      title_english: "",
      content: "",
      content_english: "",
      caption_image: "",
      author: "",
      categories: [],
      pictures: [],
    },
    saveChanges: false,
    user: {},
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Blog" : "Editar Blog";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    getBlogs() {
      this.loadingData = true;
      blogsCatService.getAllRecords().then((record) => {
        this.categoriesList = record.value;
      });
      blogsService.getViewList().then((record) => {
        this.blogs = record.value;
      }).catch(()=>{
        this.snackText = 'Ha ocurrido un error!';
        this.snackBar = true;
      }).finally(()=>{
        this.loadingData = false;
      });
    },
    newItem() {
      this.imagePreview = null;
      this.imageFile = null;
      this.selectedImageIndex = -1;
      this.valid = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    editItem(item) {
      this.editedIndex = this.blogs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.pictures.length > 0) {
        this.imagePreview = this.editedItem.pictures[0].url;
        this.selectedImageIndex = this.editedItem.pictures[0].id;
      } else {
        this.imagePreview = null;
        this.selectedImageIndex = -1;
      }
      this.imageFile = null;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.blogs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.saveChanges = true;
      blogsService.deleteRecord(this.editedItem.id).then((response) => {
        if (response.success) {
          this.blogs.splice(this.editedIndex, 1);
          this.snackText = "Registro eliminado con éxito.";
          this.closeDelete();
          this.getBlogs();
        } else {
          this.snackText = "Un error impidió eliminar el registro!";
        }
      }).catch(()=>{
        this.snackText = "Un error impidió eliminar el registro!";
      }).finally(()=>{
        this.saveChanges = false;
        this.snackBar = true;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.imagePreview = null;
        this.selectedImageIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.imagePreview == null) {
        this.messageError =
          "El formulario no contiene imagen asociada. Por favor agrégale una!!";
        this.dialogError = true;
        return;
      }
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.saveChanges = true;
        const name = this.user.name;
        const lastname = this.user.last_name;
        this.editedItem.author = `${name} ${lastname}`;
        if (this.editedIndex > -1) {
          Object.assign(this.blogs[this.editedIndex], this.editedItem);
          blogsService
            .updateRecord(
              this.editedItem,
              this.selectedImageIndex,
              this.imageFile
            )
            .then((response) => {
              if (response.success) {
                this.getBlogs();
                this.close();
                this.snackText = "Registro guardado con éxito.";
              }
              else{
                this.snackText = 'Ha ocurrido un error!';
              }
            }).catch(()=>{
              this.snackText = 'Ha ocurrido un error!';
            }).finally(()=>{
              this.snackBar = true;
              this.saveChanges = false;
            });
        } else {
          this.blogs.push(this.editedItem);
          blogsService
            .addRecord(this.editedItem, -1, this.imageFile)
            .then((response) => {
              if (response.success) {
                this.getBlogs();
                this.close();
                this.snackText = "Registro guardado con éxito.";
              }
              else{
                this.snackText = 'Ha ocurrido un error!';
              }
            }).catch(()=>{
              this.snackText = 'Ha ocurrido un error!';
            }).finally(()=>{
              this.snackBar = true;
              this.saveChanges = false;
            });
        }
      }
    },
    changePreviewImage() {
      if (this.imageFile) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(this.imageFile);
        if (this.editedItem.pictures.length === 0) {
          let imagePicture = {
            id: 2,
            module_id: 2,
            url: this.imageFile.name,
          };
          this.editedItem.pictures.push(imagePicture);
        }
      }
    },
    closeDialogError() {
      this.dialogError = false;
      this.messageError = "";
    },
  },
  created() {
    this.blogs = [];
    this.getBlogs();
    changeInformationSEO();
  },
  beforeMount() {
    this.user = this.$store.state.user;
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
